<main>
    <div class="container mt-3">
        <div class="row">
            <div class="col">
                <h2>Regulamin domków letniskowych</h2>
                <p>Szanowni Państwo!</p>
                <p>Serdecznie witamy w domkach letniskowych „Łebskie Chatki”. Mamy nadzieje, że za pomocą
                    poniższego regulaminu uda nam się zapewnić Państwu bezpieczny i miły wypoczynek.</p>
                <p>1. Państwa wypoczynek jest dla nas priorytetem :)</p>
                <p>2. Goście przebywający w domkach letniskowych „Łebskie Chatki” proszeni są o przestrzeganie zasad
                    bezpiecznego i kulturalnego wypoczynku.</p>
                <p>3. O Państwa komfortowy pobyt i spokojny wypoczynek na miejscu dbają Właściciele, wszelkie uwagi,
                    usterki prosimy zgłaszać na bieżąco pod nr telefonu 734 734 656 lub 692 848 890.</p>
                <p>4. Doba hotelowa trwa od godz. 16:00 w dniu przyjazdu do godz. 10:00 dnia następnego.</p>
                <p>5. Rezerwację należy potwierdzić wpłatą zadatku, co jest równoznaczne z akceptacją warunków
                    rezerwacji i niniejszego regulaminu. Pozostałą część opłaty za pobyt wraz z opłatą klimatyczną wg
                    aktualnego cennika należy uiścić w dniu wyłącznie gotówką przyjazdu.</p>
                <p>6. Rezygnacja z części pobytu, późniejszy przyjazd oraz wcześniejsze opuszczenie domków, spowodowane
                    nie z winy właściciela kompleksu, nie uprawnia do zwrotu wpłaconych należności za pobyt.</p>
                <p>7. Całkowita rezygnacja z pobytu nie uprawnia do zwrotu wpłaconego zadatku.</p>
                <p>8. Do korzystania z domków letniskowych uprawnione są tylko osoby zakwaterowane.
                    Osoby postronne mogą przebywać na terenie obiektu do godz. 21.30.</p>
                <p>9. Na terenie kompleksu obowiązuje cisza nocna w godzinach 23:00 - 7:00. Nie mniej
                    jednak korzystający z usług obiektu nie mogą zakłócać wypoczynku innym
                    wczasowiczom szczególnie korzystając z tarasu.</p>
                <p>10. W przypadku zakłócania porządku i nie przestrzegania regulaminu na terenie
                    kompleksu jego właściciel ma prawo zażądać jego natychmiastowego opuszczenia bez
                    zwrotu opłaty.</p>
                <p>11. W domkach obowiązuje całkowity zakaz palenia papierosów, smażenia ryb,
                    używania własnych kuchenek i piecyków elektrycznych oraz używania otwartego
                    ognia (np. świeczek). Grilla można używać wyłącznie na kostce brukowej znajdującej
                    się przy domku.</p>
                <p>12. W przypadku chłodniejszych dni zabezpieczamy Państwu własny sprzęt grzejący
                    zgodnie z obowiązującą opłatą.</p>
                <p>13. „Łebskie Chatki” nie podejmują się ubezpieczenia gości. Wczasowicze przebywający
                    na terenie kompleksu powinni ubezpieczyć się we własnym zakresie.</p>
                <p>14. Do dyspozycji Państwa jest plac zabaw dla dzieci ale przebywanie na nim może
                    odbywać się tylko pod opieką rodziców lub pełnoletnich opiekunów. Ze względów
                    bezpieczeństwa prosimy pilnować aby na placu nie przebywało zbyt dużo dzieci
                    jednocześnie (max 4 na wieżyczkach). W związku z tym, że to rodzice ponoszą pełną
                    odpowiedzialność za bezpieczeństwo i za wszelkie skutki działań swoich dzieci, właściciel
                    obiektu zwraca się z prośbą o wzmożoną opiekę nad nimi.</p>
                <p>15. Teren całego obiektu jest monitorowany całodobowo. Monitoring prowadzony jest
                    wyłącznie w celu zapewnienia bezpieczeństwa klientom znajdującym się na terenie
                    Łebskich Chatek.
                    Administratorem Państwa danych są Łebskie Chatki, ul. Żwirowa 1, 84-360 Nowęcin.
                    Zapisy będą przechowywane przez administratora przez okres 30 dni. Więcej informacji
                    można uzyskać pod adresem email: lebskiechatki&#64;gmail.com.</p>
                <p>16. Każdy z gości jest zobowiązany do uważnego obchodzenia się z wyposażeniem
                    domków. O powstałych szkodach należy niezwłocznie zawiadomić właściciela. Za
                    powstałą z winy gości szkodę lub braki w wyposażeniu domku, goście ponoszą pełną
                    odpowiedzialność materialną.</p>
                <p>17. Wszelkie usterki sprzętu oraz mienia wynikłe z użytkowania prosimy zgłaszać
                    niezwłocznie właścicielowi obiektu.</p>
                <p>18. Zabrania się samodzielnych napraw, modernizacji sprzętu będącego na wyposażeniu
                    domków.</p>
                <p>19. Właściciel obiektu nie ponosi odpowiedzialności materialnej za rzeczy należące do
                    gości pozostawione w domku lub na terenie kompleksu, jak również nie ponosi
                    odpowiedzialności prawnej jak i materialnej za szkody lub kradzież samochodów
                    pozostawionych na parkingu kompleksu (parking monitorowany niestrzeżony).
                    Obowiązek zabezpieczenia swoich rzeczy przed kradzieżą i zniszczeniem spoczywa na ich
                    właścicielach. Prosimy o zachowanie należytej staranności związanej z zamykaniem
                    okien i drzwi w momencie opuszczania domku.</p>
                <p>20. Goście nie mogą przekazywać domku letniskowego osobom trzecim, nawet jeśli nie
                    upłynął okres, za który uiścili należną za pobyt opłatę.</p>
                <p>21. Odbiór i zdanie domku odbywa się zawsze w towarzystwie Właściciela lub osoby
                    przez niego upoważnionej. Prosimy o wcześniejsze zgłaszanie właścicielowi godziny
                    wyjazdu, w celu umożliwienia sprawnego odbioru domku.</p>
                <p>22. Przed opuszczeniem kompleksu należy posprzątać domek, teren wokół oraz wyrzucić
                    nieczystości do pojemników na śmieci w przeciwnym razie może być naliczona
                    dodatkowa opłata 50 zł.</p>
                <p>23. W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają
                    odpowiednio przepisy Kodeksu Cywilnego oraz Ustawy o usługach turystycznych.</p>
                <p>Dziękujemy za uwagę :)</p>
            </div>
        </div>
    </div>

</main>