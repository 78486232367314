import { Component, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewRef } from '@angular/core'
import * as $ from "jquery"
import { RouterLink } from '@angular/router';
import { AppComponent } from '../app.component'

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class NavigationComponent implements OnInit {

  constructor(private renderer:Renderer2,
              public app:AppComponent) { }

  ngOnInit(): void {}

  @ViewChild('navlist') navlist
  hideNav(){
    this.renderer.removeClass(this.navlist.nativeElement,"show") 
    this.app.scrollTop()
  }
}
