<div id="social" class="d-flex flex-row justify-content-around px-md-5 py-1 align-items-center">
  <a class="noSelect" href="https://www.facebook.com/lebskiechatki/">
    <img src="../assets/social/facebook-icon.jpg" alt="facebook">
  </a>
  <a class="noSelect" href="https://www.instagram.com/lebskiechatki/">
    <img src="../assets/social/instagram-icon.jpg" alt="instagram">
  </a>
  <a class="noSelect"
    href="https://www.google.com/maps/place/%C5%81ebskie+Chatki/@54.7492031,17.5856794,17z/data=!3m1!4b1!4m5!3m4!1s0x46fe781be2d7ecf3:0x53a4b29dc0d4bac5!8m2!3d54.7492!4d17.5878681">
    <img src="../assets/social/googlemaps-icon.jpg" alt="google-maps">
  </a>
</div>
<div #links id="links" class="row">

  <div class="col-sm-12 col-md-4">
    @if (!this.app.home) {
    <div class=" d-flex flex-column p-3  justify-content-around h-100">
      <div class="d-flex flex-column text-white text-justify">
        <span class="font-weight-bold">Zapraszamy</span>
        <span>Wynajmij u nas domek. Odpocznij nad morzem i jeziorem. Wakacje w Łebie dla całej rodziny.</span>
      </div>
      <div class="d-flex flex-column text-white">
        <span class="font-weight-bold">Odwiedź również:</span>
        <a class="noSelect text-white" href="http://www.agraleba.pl">www.agraleba.pl - Pokoje w centrum Łeby.</a>
      </div>
    </div>
    }
  </div>
  <div id="map" [ngClass]="(this.app.home)?'col-md-12':'col-md-8'" class="col-sm-12">
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe width="100%" height="100%"
          src="https://maps.google.com/maps?q=%C5%81ebskie%20Chatki&t=&z=11&ie=UTF8&iwloc=&output=embed" frameborder="0"
          scrolling="no"></iframe>
      </div>
    </div>
  </div>
</div>
<footer class="d-flex flex-column align-items-center py-4 text-light text-center">

  <small>
    <div id="copyright">Wszystkie prawa zastrzeżone © Łebskie Chatki 2024</div>
    <div id="small_menu" class="d-flex pt-3 text-center justify-content-between">
      <a (click)="app.scrollTop()" class="user-select-none text-light w-100" routerLink="/gallery">Galeria</a>
      <a (click)="app.scrollTop()" class="user-select-none text-light w-100" routerLink="/">Start</a>
      <a (click)="app.scrollTop()" class="user-select-none text-light w-100" routerLink="/contact">Kontakt</a>
    </div>
  </small>
</footer>