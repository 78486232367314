import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [NgClass, RouterLink]
})
export class FooterComponent implements OnInit {
  constructor(public app:AppComponent) { }

  ngOnInit(): void {

  }

}
