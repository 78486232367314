<main>
    <div class="container mt-5">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-5 col-lg-4">
                <div class="d-flex justify-content-center mb-4 mb-md-0">
                    <img id="logoslide" src="../assets/logo/logo_lebskiechatki.png" alt="logo_lebskiechatki">
                </div>
            </div>
            <div class="col-md-5 col-lg-4">
                <h3>Kontakt</h3>
                <div class="contact_info">
                    <p><b>Telefon:</b> +48 734 734 656</p>
                    <p><b>Adres:</b> Nowęcin ul.Żwirowa 1</p>
                    <p><b>Poczta:</b> 84-360 Łeba</p>
                    <p><b>Email:</b> <a href="mailto:lebskiechatki@gmail.com?subject=Rezerwacja Łebskie Chatki"
                            class="user-select-none text-dark"> lebskiechatki&#64;gmail.com</a></p>
                </div>
            </div>
        </div>
    </div>

</main>