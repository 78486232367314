<main>
    <div class="d-flex justify-content-center">
        <div id="gallery">
            <button id="prev"> ❮ </button>
            <button id="next"> ❯ </button>
            <div id="button_gallery">
                <img class="active" srcset="../assets/photos/(1).jpg,
                ../assets/mobile/photos/(1).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(2).jpg,
                ../assets/mobile/photos/(2).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(3).jpg,
                ../assets/mobile/photos/(3).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(4).jpg,
                ../assets/mobile/photos/(4).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(5).jpg,
                ../assets/mobile/photos/(5).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(6).jpg,
                ../assets/mobile/photos/(6).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(7).jpg,
                ../assets/mobile/photos/(7).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(8).jpg,
                ../assets/mobile/photos/(8).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(9).jpg,
                ../assets/mobile/photos/(9).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(12).jpg,
                ../assets/mobile/photos/(12).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(13).jpg,
                ../assets/mobile/photos/(13).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(14).jpg,
                ../assets/mobile/photos/(14).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(15).jpg,
                ../assets/mobile/photos/(15).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(16).jpg,
                ../assets/mobile/photos/(16).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(17).jpg,
                ../assets/mobile/photos/(17).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(18).jpg,
                ../assets/mobile/photos/(18).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(19).jpg,
                ../assets/mobile/photos/(19).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(20).jpg,
                ../assets/mobile/photos/(20).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(21).jpg,
                ../assets/mobile/photos/(21).jpg 420w" sizes="100vw" alt="zdjecia">
                <img srcset="../assets/photos/(22).jpg,
                ../assets/mobile/photos/(22).jpg 420w" sizes="100vw" alt="zdjecia">
            </div>
        </div>
    </div>
    <div class="container mt-2" id="info">
        <div class="row">
            <div class="col col-md-8 offset-md-2">
                <h3 class="text-center">Wyposażenie</h3>
                <p>Każdy domek posiada taras z meblami ogrodowymi, salon z jadalnią, z widokiem na ogród, w pełni
                    wyposażoną kuchnię oraz łazienkę. W pokoju sypialnianym znajdują się dwa pojedyncze łóżka. Na
                    piętrze jest antresola z podwójnym łożem oraz widokiem na salon. W salonie jest rozkładana
                    dwuosobowa sofa. Dla najmłodszych na życzenie zapewniamy fotelik karmiący i łóżeczko niemowlęce.
                    Mamy plac zabaw z małym drewnianym domkiem, dużą piaskownicą i mnóstwo ogrodzonego terenu do zabawy
                    ponad 1000m2 trawy do biegania ;) Zapewniamy bezpłatny monitorowany parking i wi-fi.</p>
            </div>
        </div>
    </div>
</main>