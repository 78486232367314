<main>
    <div id="logodiv">
        <img id="logoslide" src="../assets/logo/logo2019.png" alt="logo_lebskiechatki">
    </div>
    <div id="slide_show">
        <img srcset="../assets/slide_show/slide.jpg,
        ../assets/mobile/slide_show/slide.jpg 500w" sizes="100vw" alt="zdjecie główne">
    </div>
    <div class="container mt-3">
        <div class="row text-justify mx-lg-5">
            <div class="d-flex flex-column mx-auto col-md-7 col-lg-6">
                <h3 class="text-center">O nas</h3>
                <p>Łebskie chatki to kameralne domki położone w miejscowości Nowęcin przy granicy z Łebą. Posiadają
                    salon z pełni wyposażonym aneksem kuchennym, łazienkę, sypialnię oraz antresolę (z funkcją
                    dodatkowej sypialni). Na wyposażeniu jest TV (32" DVB-T, USB), WiFi, meble ogrodowe i grill. Do
                    dyspozycji gości jest również darmowy parking a dla Najmłodszych plac zabaw (4 wieżyczki z
                    przeszkodami).</p>
            </div>
            <div class="d-flex flex-column mx-auto col-md-5 col-lg-4">
                <h3 class="text-center">Okolica</h3>
                <p>Domki znajdują się na ogrodzonej działce w spokojnej okolicy, z dala od turystycznego zgiełku. Do do
                    morza jest ok 35 min., jeziora Sarbsko ok 400 m, do stadniny koni 250 m. Wszędzie można dojechać
                    autobusem, który zatrzymuje się tuż obok, melexem lub drogą rowerową. W okolicy znajdują się sklepy,
                    bary i restauracje.</p>
            </div>
        </div>
    </div>
    <div id="post1" class="post container mt-3 left-anim">
        <div class="row mx-lg-5">
            <div class="d-flex flex-column justify-content-center align-items-center mx-auto col-md-6">
                <img src="../assets/home/taras.jpg" alt="taras">
                <div class="inside-text d-flex flex-column justify-content-center align-items-center">
                    <h3 class="text-center">Taras</h3>
                    <span class="text-center">Miejsce do grillowania i odpoczynku.</span>
                </div>
            </div>
            <div class="post-oceny d-flex flex-column justify-content-center align-items-start mx-auto col-md-6">
                <h3 class="text-center">Booking</h3>
                <div class="d-flex flex-row justify-content-beetween">
                    <img src="../assets/home/stars.png" alt="gwiazdki">
                    <span class="text-center">9,1/10</span>
                </div>
                <span class="">„Bardzo polecam! :) przemili właściciele, bardzo ładne domki, w których 
                    jest wszystko co potrzeba. Duży taras. Świetna lokalizacja...”
                </span>
            </div>
        </div>
    </div>
    <div id="post2" class="post container mt-3 right-anim">
        <div class="row mx-lg-5">
            <div class="post-image d-flex flex-column justify-content-center align-items-center mx-auto col-md-6 order-md-last">
                <img src="../assets/home/placzabaw.jpg" alt="plac zabaw">
                <div class="inside-text d-flex flex-column justify-content-center align-items-center">
                    <h3 class="text-center">Plac zabaw</h3>
                    <span class="text-center">4 wieżyczki z przeszkodami i domek.</span>
                </div>
            </div>
            <div class="post-oceny d-flex flex-column justify-content-center align-items-end mx-auto col-md-6">
                <h3 class="text-center">Nocowanie</h3>
            <div class="d-flex flex-row justify-content-beetween">
                <img src="../assets/home/stars.png" alt="gwiazdki">
                <span class="">10/10</span>
                </div>
                <span class="text-right">„Fajne nowe domki, zadbany teren zielony, super plac zabaw. Polecam :)”
                </span>
            </div>
        </div>
    </div>
    <div id="post3" class="post container mt-3 left-anim">
        <div class="row mx-lg-5">
            <div class="d-flex flex-column justify-content-center align-items-center mx-auto col-md-6">
                <img src="../assets/home/aneks.jpg" alt="aneks">
                <div class="inside-text d-flex flex-column justify-content-center align-items-center">
                    <h3 class="text-center">Aneks kuchenny</h3>
                    <span class="text-center">Mała kuchenka, lodówka, czajnik.</span>
                </div>
            </div>
            <div class="post-oceny  d-flex flex-column justify-content-center align-items-start mx-auto col-md-6">
                <h3 class="text-center">Google</h3>
                <div class="d-flex flex-row justify-content-beetween">
                    <img src="../assets/home/stars.png" alt="gwiazdki">
                    <span class="">4.9/5</span>
                </div>
                <span class="">„Wspaniali właściciele, piękne, urządzone w bardzo klimatyczny sposób drewniane domki,
                    spokojna okolica, niczego więcej mi tutaj nie potrzeba.”
                </span>
            </div>
        </div>
    </div>
</main>