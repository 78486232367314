<nav class="navbar navbar-expand-lg navbar-dark nav-panel">
  <div class="container-fluid">
    <a href="tel:+48734734656" class="phone-number fw-bold user-select-none text-light text-decoration-none">+48 734 734 656</a>
    <a class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false">
      <span class="navbar-toggler-icon"></span>
    </a>
    <div #navlist class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mb-2 mb-lg-0">
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link active" aria-current="page" routerLink="/">Łebskie Chatki</a>
        </li>
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link" routerLink="/gallery">Galeria</a>
        </li>
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link" routerLink="/contact">Kontakt</a>
        </li>
        <li class="nav-item">
          <a (click)="hideNav()" class="nav-link" routerLink="/policy">Regulamin</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
