import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.css'],
    standalone: true
})
export class PolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
